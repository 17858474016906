import { GlobalPairingRoundCreate } from '../components/Pairing/GlobalRound';

function GlobalPairingRoundCreateRoute() {
  return <GlobalPairingRoundCreate />;
}

export const Component = GlobalPairingRoundCreateRoute;

export function clientLoader() {
  return null;
}
